import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import RoleplayContent from "../components/roleplayContent";
import i18nMessages from '../i18n/en.json';
import 'intl/locale-data/jsonp/en';
import PageWrapper from "../components/pageWrapper";

const Roleplay = (props) => {
    
    return (
        <PageWrapper location={props.location} lang="en" i18nMessages={i18nMessages}>
            <RoleplayContent location={props.location} langUrl="" />
        </PageWrapper>
    )
}

export default Roleplay

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`